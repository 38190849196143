<template>
  <vs-chip :class="['ag-grid-cell-chip', chipClass(params.data.type)]">
    <span class="text-center" v-if="params.data.type">{{
      labeler(params.data.type)
    }}</span>
  </vs-chip>
</template>

<script>
export default {
  name: 'CellRendererInventoryHistoryType',
  computed: {
    chipClass() {
      const classes = {
        STOCK: 'stock',
        PRICE: 'price',
        NAMING: 'naming',
      };

      return (value) => {
        return classes[value] ?? classes.DEFAULT;
      };
    },
    labeler() {
      const labels = {
        STOCK: 'Stock',
        PRICE: 'Precio',
        NAMING: 'Nombres',
      };

      return (value) => {
        return labels[value] ?? labels.DEFAULT;
      };
    },
  },
};
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  margin: 0 auto;
  @apply font-bold;
  &.price {
    background-color: #5c52a9;

    .vs-chip--text {
      @apply text-white;
    }
  }

  &.stock {
    background-color: #3e874c;
    .vs-chip--text {
      @apply text-white;
    }
  }

  &.naming {
    background-color: #376f94;
    .vs-chip--text {
      @apply text-white;
    }
  }
}
</style>
