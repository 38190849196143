<template>
  <div>
    <vx-card>
      <div class="flex justify-between items-center gap-3">
        <div class="w-full">
          <label class="text-sm text-san-juan font-bold">Buscar</label>
          <vs-input
            class="mb-4 w-full md:mb-0 mr-4"
            v-model="searchQuery"
            placeholder="Buscar inventario"
          />
        </div>
        <div class="flex gap-3">
          <div class="w-full">
            <label class="text-sm text-san-juan font-bold"
              >Fecha de inicio</label
            >
            <datepicker
              :language="langEs"
              name="start-date"
              v-model="startDate"
            ></datepicker>
          </div>
          <div class="w-full">
            <label class="text-sm text-san-juan font-bold">Fecha de fin</label>
            <datepicker
              :language="langEs"
              name="end-date"
              v-model="endDate"
            ></datepicker>
          </div>

          <div class="mt-6">
            <vs-button
              type="border"
              class="mb-4 md:mb-0 mr-3 whitespace-no-wrap"
              @click="exportInventoryHistory"
            >
              Exportar
            </vs-button>
          </div>
        </div>
      </div>

      <div v-if="!loading && !inventoryHistoryLoading">
        <ag-grid-vue
          ref="agGridTable"
          :gridOptions="agGridState.gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="agGridState.defaultColDef"
          :rowData="inventoryHistory"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
          :overlayLoadingTemplate="overlayLoadingTemplate"
        >
        </ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="agGridState.maxPageNumbers"
          v-model="currentPage"
        />
      </div>
      <div v-else class="h-64 my-24 flex justify-center items-center">
        <p class="text-grey">Cargando...</p>
      </div>
    </vx-card>
  </div>
</template>

<script>
import moment from 'moment';
import CellRendererAmount from '@/components/cell-renderer/CellRendererAmount.vue';
import CellRendererPercentAmount from '@/components/cell-renderer/CellRendererPercentAmount.vue';
import { useAgGrid } from '@/composable/useAgGrid.js';
import { INVENTORY_HISTORY } from '@/graphql/queries.js';
import { useQuery, useResult } from '@vue/apollo-composable';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import Datepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/src/locale';
import CellRendererInventoryHistoryType from './cell-renderer/CellRendererInventoryHistoryType.vue';

export default defineComponent({
  components: {
    CellRendererAmount,
    CellRendererPercentAmount,
    CellRendererInventoryHistoryType,
    Datepicker,
  },
  setup(_, { root }) {
    const state = reactive({
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          width: 120,
          filter: true,
        },
        {
          headerName: 'Tipo',
          field: 'type',
          width: 120,
          filter: true,
          cellRendererFramework: 'CellRendererInventoryHistoryType',
        },
        {
          headerName: 'Observación',
          field: 'description',
          width: 300,
          filter: true,
        },
        {
          headerName: 'Nombre',
          field: 'inventory.name',
          width: 300,
          filter: true,
        },
        {
          headerName: 'Precio',
          field: 'inventory.price',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererAmount',
        },
        {
          headerName: 'Stock',
          field: 'inventory.stock',
          filter: true,
          width: 100,
        },
        {
          headerName: 'Editado por',
          field: 'creatorUser.first_name',
          width: 150,
          filter: true,
        },
        {
          headerName: 'Compañia',
          field: 'inventory.company.name',
          width: 150,
          filter: true,
        },
        {
          headerName: 'Creación',
          field: 'created_at',
          width: 200,
          filter: true,
        },
      ],
      loading: false,
      overlayLoadingTemplate:
        '<span>Please wait while your rows are loading</span>',
      startDate: new Date(),
      endDate: new Date(),
    });

    const currentPage = ref(1);
    const first = ref(20);
    const searchQuery = ref('');
    const paginationPageSize = ref('');

    const sDate = computed(() => moment(state.startDate).format('YYYY-MM-DD'));
    const eDate = computed(() => moment(state.endDate).format('YYYY-MM-DD'));

    const { result: inventoryHistoryResult, loading: inventoryHistoryLoading } =
      useQuery(INVENTORY_HISTORY, {
        page: currentPage,
        first: first,
        search: searchQuery,
        startDate: sDate,
        endDate: eDate,
      });

    watch(inventoryHistoryLoading, (value) => {
      state.loading = value;
    });

    const inventoryHistory = useResult(inventoryHistoryResult, [], (data) => {
      console.log('🚀 inventoryHistory', data.inventoryHistory.data.length);
      return data.inventoryHistory.data;
    });

    const paginatorInfo = useResult(inventoryHistoryResult, [], (data) => {
      console.log('🚀 paginatorInfo inventoryHistory', { data });
      return {
        total: data.inventoryHistory.paginatorInfo.total,
        perPage: data.inventoryHistory.paginatorInfo.perPage,
      };
    });

    const totalPages = computed(() =>
      Math.ceil(paginatorInfo.value.total / paginatorInfo.value.perPage)
    );

    const { state: agGridState } = useAgGrid({ root });

    const exportInventoryHistory = () => {
      const fileName = 'historial-inventario.xlsx';
      root.$http
        .post(
          'api/inventory-history/export',
          {
            search: searchQuery.value,
            startDate: sDate.value,
            endDate: eDate.value,
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    };

    return {
      ...toRefs(state),
      inventoryHistoryLoading,
      paginationPageSize,
      agGridState,
      totalPages,
      currentPage,
      inventoryHistory,
      searchQuery,
      langEs: es,
      exportInventoryHistory,
    };
  },
});
</script>
